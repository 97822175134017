import React from 'react'
import Updates from './updates.component'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Header = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "header-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const bgImageData = bgImage.fileName.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="header"
      className="Header"
      fluid={bgImageData}
      backgroundColor={`#2b2b2b`}
    >
      <div className="Header__overlay"></div>
      <div className="Header__line"></div>
      <div className="Header__text">
        <h1 className="Heading--primary">
          Constantly Innovating <br />
          Always Improving <br />
        </h1>

        <p>Software that enhances your customer experience </p>
      </div>

      <Updates />
    </BackgroundImage>
  )
}
export default Header
