import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Update = ({ image, title, description, slug }) => {
  const styles = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
  }

  return (
    <Link to={`${slug}`} className="Update">
      <div className="Update__img" style={styles}></div>
      <div className="Update__text">
        <h3 className="Update__title">{title}</h3>
        <p className="Update__description">{description.substring(0, 20)}...</p>
      </div>
    </Link>
  )
}

Update.defaultProps = {
  title: 'Company Update',
  description: 'Lorem ipsum dolor',
}

Update.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Update
