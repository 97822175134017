import React, { useEffect, useState } from 'react'
import SEO from '../components/seo.component'
import Layout from '../components/layout.component'
import Header from '../components/header/header.component'
import AboutSection from '../components/about-section/about-section.component'
import PortfolioSection from '../components/portfolio-section/portfolio-section.component'
import UpdatesSection from '../components/updates-section/updates-section.component'
// import firebase from 'gatsby-plugin-firebase'

/// Base component for the home page
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Header />
      <AboutSection />
      <PortfolioSection />
      <UpdatesSection />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#673d90',
        }}
        dangerouslySetInnerHTML={{
          __html: `
<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
    <form action="https://ansonervin.us10.list-manage.com/subscribe/post?u=09127a3974e1575119ea6ab49&amp;id=75e9f2f0af&amp;f_id=009bd2e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
        <h2>Subscribe to our mailing list for offers</h2>
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
	<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_09127a3974e1575119ea6ab49_75e9f2f0af" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->
`,
        }}
      ></div>
    </Layout>
  )
}

export default IndexPage
