import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Project from './project.component'
import { FaArrowRight } from 'react-icons/fa'

const PortfolioSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 3
        filter: { fileAbsolutePath: { regex: "/projects/" } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              subtitle
              thumbnail {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className="PortfolioSection">
      <div className="dFlex mb">
        <div className="headings">
          <h3 className="Heading--tertiary">Our work</h3>
          <h2 className="Heading--secondary">Portfolio</h2>
        </div>
        <Link to="/portfolio" className="Btn Btn--no-bg">
          View all <FaArrowRight className="icon" />
        </Link>
      </div>
      <div className="dFlex">
        {data.allMarkdownRemark.edges.map((edge, i) => {
          return (
            <Project
              key={edge.node.id}
              image={
                edge.node.frontmatter.thumbnail.childImageSharp.original.src
              }
              title={edge.node.frontmatter.title}
              description={edge.node.frontmatter.subtitle}
              slug={edge.node.fields.slug}
            />
          )
        })}
      </div>
    </section>
  )
}

export default PortfolioSection
