import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Update from './update.component'
import { FaArrowRight } from 'react-icons/fa'

const Updates = () => {
  const updates = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { fileAbsolutePath: { regex: "/blog/" } }
        limit: 3
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              subtitle
              date
              thumbnail {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `)

  const updatesList = updates.allMarkdownRemark.edges.map((edge) => {
    const id = edge.node.id
    const slug = edge.node.fields.slug
    const { title, subtitle, thumbnail } = edge.node.frontmatter

    return (
      <Update
        key={id}
        slug={slug}
        title={title}
        description={subtitle}
        image={thumbnail.childImageSharp.original.src}
      />
    )
  })

  return (
    <div className="Updates">
      {updatesList}
      <Link to="/blog" className="Updates__view-more">
        <p>View more updates</p> <FaArrowRight />
      </Link>
    </div>
  )
}

export default Updates
